import { isProduction } from '..';
import type { AppConfig } from '../types';

export default {
  zone: 'asia-7',
  defaultLocale: 'en-SG',
  availableLocales: ['en-SG'],
  timedBanners: {
    cookieConsent: {
      displayDates: {
        startDate: '2023-10-15T00:00:00.000Z',
      },
    },
    launchBanner: {
      displayDates: {
        startDate: '2024-08-30T11:00:00.000Z', // 30 August 2024, 19:00:00 Local Time
        endDate: '2024-09-13T16:00:00.000Z', // 14 September 2024, 00:00:00 Local Time
      },
      bannerUrl: {
        en: 'https://help.sg.jobstreet.com/s/article/Why-has-the-website-address-changed-SG',
      },
      oldUrl: 'jobstreet.com.sg',
      newUrl: 'sg.jobstreet.com',
    },
  },
  zoneFeatures: {
    NUDGE_ENABLED: false,
    HOMEPAGE_BANNER_TYPE: 'GENERIC_ASIA_BANNER',
    SEARCH_PAGE_SIZE: 30,
    SHOW_FLOATING_SAVE_SEARCH: false,
    AUTO_SELECT_SPLIT_VIEW_FIRST_JOB: false,
    SHOW_MARKETING_AD_SPLIT_VIEW: false,
    LMIS_ENABLED: true,
    BEHAVIOURAL_CUES_ENABLED: false,
    BEHAVIOURAL_CUES_FILTERS_ENABLED: false,
    LOGGED_OUT_RECS: false,
    REMOTE_SEARCH_FILTER: false,
    REMOTE_SEARCH_FILTER_NEW_LABEL: true,
    DYNAMIC_PILLS: false,
    SECONDARY_FILTERS: false,
    ENABLE_V5_JOB_SEARCH: false,
    KEYWORD_AUTOSUGGEST_V2: true,
    NEW_JOB_CARD_DENSITY: false,
    ENABLE_VERIFIED_HIRER_BADGE: true,
    SERP_JOBCARD_INFO_DENSITY_1: false,
    MATCHED_QUALITIES: false,
  },
  BRANCH_IO_KEY: isProduction
    ? 'key_live_keVW4JKlS0jBJLPfSRNUJnhhtElmK541'
    : 'key_test_igQ77GMmQ1itGVIiMGXzojmavAcg9pio',
} as AppConfig;
