import gql from 'graphql-tag';

export const SEARCH_SAVED_AND_APPLIED_JOBS = gql`
  query SearchSavedAndAppliedJobs($jobIds: [String!]!) {
    viewer {
      searchSavedJobs(jobIds: $jobIds) {
        jobId
      }
      searchAppliedJobs(jobIds: $jobIds) {
        jobId
      }
    }
  }
`;

export const GET_SAVED_JOBS = gql`
  query GetSavedJobs($locale: Locale) {
    viewer {
      id
      savedJobs(first: 3) {
        total
        edges {
          node {
            id
            isActive
            job {
              id
              title
              advertiser {
                name(locale: $locale)
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_HOME_SAVED_JOBS = gql`
  query GetHomeSavedJobs($timezone: Timezone!, $locale: Locale!, $zone: Zone!) {
    viewer {
      id
      savedJobs(first: 3) {
        total
        edges {
          node {
            id
            isActive
            job {
              id
              title
              advertiser {
                name(locale: $locale)
              }
              salary {
                currencyLabel(zone: $zone)
                label
              }
              location {
                label(locale: $locale)
              }
              listedAt {
                label(
                  context: JOB_POSTED
                  length: SHORT
                  timezone: $timezone
                  locale: $locale
                )
              }
              products {
                branding {
                  logo {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
