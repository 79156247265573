import {
  useApolloClient,
  type ApolloClient,
  type NormalizedCacheObject,
} from '@apollo/client';
import type { SearchResultJob, SearchResultJobV5 } from '@seek/chalice-types';
import { metrics } from '@seek/metrics-js';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { v4 as uuid } from 'uuid';

import useJobDetail from 'src/components/SearchResultPage/SplitView/JobDetail/useJobDetail';
import {
  persistJobUrl,
  removePersistedJobUrl,
} from 'src/components/SearchResultPage/SplitView/helpers';
import { useAppConfig } from 'src/config/appConfig';
import type { HubbleJDVEventTriggerTag } from 'src/hooks/trackJobDetailsLoaded';
import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import {
  fetchUnifiedJob,
  fetchUnifiedJobPersonalised,
} from 'src/store/jobdetails';
import { setSelectedJobIdAction } from 'src/store/results';
import {
  selectAuthenticated,
  selectLocation,
  selectSelectedJobId,
  selectSessionId,
  selectJobDetailsResult,
  selectXRealIp,
} from 'src/store/selectors';

import { useDispatch, useSelector } from '../store/react';

import useShouldFetchJobDetails from './useShouldFetchJobDetails';

const mapEventTriggerToJobListingPosition = (
  trigger: HubbleJDVEventTriggerTag,
) => {
  switch (trigger) {
    case 'pre_selected':
      return -1;
    case 'auto_display':
      return 0;
    default:
      return undefined;
  }
};

const useFetchJobDetails = (job?: SearchResultJob | SearchResultJobV5) => {
  const dispatch = useDispatch();
  const analyticsFacade = useAnalyticsFacade();
  const currentJobDetails = useSelector(selectJobDetailsResult);
  const jobseekerSessionId = useSelector(selectSessionId) ?? '';
  const authenticated = useSelector(selectAuthenticated);
  const {
    zone,
    country: countryCode,
    locale,
    language: languageCode,
  } = useAppConfig();
  const apolloClient = useApolloClient() as ApolloClient<NormalizedCacheObject>;
  const shouldFetchJobDetails = useShouldFetchJobDetails(job);
  const ipAddress = useSelector(selectXRealIp);

  const fetchJobDetails = useCallback(() => {
    // Spltiview JDV is rendered client-side
    if (ENV.SERVER || !job) {
      return Promise.resolve(undefined);
    }

    const jobId = job.id.toString();
    const jobDetailsViewedCorrelationId = uuid();

    dispatch(
      fetchUnifiedJob({
        apolloClient,
        analyticsFacade,
        jobId,
        jobDetailsViewedCorrelationId,
        jobseekerSessionId,
        zone,
        locale,
        languageCode,
        shouldThrowError: false,
        countryCode,
        xRealIp: ipAddress,
      }),
    );

    if (authenticated) {
      dispatch(
        fetchUnifiedJobPersonalised({
          apolloClient,
          jobId,
          jobseekerSessionId,
          jobDetailsViewedCorrelationId,
          languageCode,
          locale,
          zone,
          xRealIp: ipAddress,
        }),
      );
    }
  }, [
    job,
    dispatch,
    apolloClient,
    analyticsFacade,
    jobseekerSessionId,
    zone,
    locale,
    languageCode,
    countryCode,
    authenticated,
    ipAddress,
  ]);

  useEffect(() => {
    if (shouldFetchJobDetails) {
      fetchJobDetails();
    }
  }, [shouldFetchJobDetails, fetchJobDetails]);

  return {
    currentJobDetails,
    fetchJobDetails,
  };
};

export const useSplitView = () => {
  const [selectedJob, setSelectedJob] = useState<
    SearchResultJob | SearchResultJobV5
  >();
  const [displayEventTrigger, setDisplayEventTrigger] =
    useState<HubbleJDVEventTriggerTag>('default');
  const dispatch = useDispatch();

  const { currentJobDetails, fetchJobDetails } =
    useFetchJobDetails(selectedJob);

  const selectedJobPosition =
    mapEventTriggerToJobListingPosition(displayEventTrigger) ??
    Number(selectedJob?.solMetadata.sectionRank);

  const currentLocation = useSelector(selectLocation);
  const selectedJobId = useSelector(selectSelectedJobId);

  const setSelectedJobAndDisplayEventTrigger = (
    job?: SearchResultJob | SearchResultJobV5,
    eventTrigger?: HubbleJDVEventTriggerTag,
  ) => {
    setSelectedJob(job);
    metrics.count('pageload');
    if (job) {
      persistJobUrl({
        currentLocation,
        job,
      });
    } else if (selectedJobId) {
      dispatch(setSelectedJobIdAction(undefined));
      removePersistedJobUrl(currentLocation);
    }
    setDisplayEventTrigger(eventTrigger ?? 'default');
  };

  // Apollo's loading state is not firing correctly, so we need to use the store state instead
  // Will be removed once Apollo's loading state is fixed
  const { isLoading } = useJobDetail();

  return {
    selectedJob,
    setSelectedJob: setSelectedJobAndDisplayEventTrigger,
    selectedJobPosition,
    eventTrigger: displayEventTrigger,
    currentJobDetails,
    fetchJobDetails,
    isLoading,
  };
};

type SplitviewContextType = ReturnType<typeof useSplitView>;

const SplitViewContext = createContext<SplitviewContextType>(
  {} as SplitviewContextType,
);

export const SplitViewContextProvider = SplitViewContext.Provider;

export const useSplitViewContext = () => useContext(SplitViewContext);
