import type { MatchedQualities } from 'src/graphql/graphql';

const skills = {
  Recruitment: {
    keyword: {
      ontologyId:
        'https://graph.cloud.seek.com/Spec-Req/0a0a569a-9519-4008-9817-4088125f97fe',
      text: 'Recruitment',
    },
  },
  Mining_Industry: {
    keyword: {
      ontologyId:
        'https://graph.cloud.seek.com/Spec-Req/6279a02e-8d2c-4893-9176-fd9be4235efc',
      text: 'Mining Industry',
    },
  },
  Drilling: {
    keyword: {
      ontologyId:
        'https://graph.cloud.seek.com/Spec-Req/3c89d92d-4eae-4b6c-8668-55a2b960d1ad',
      text: 'Drilling',
    },
  },
  General_Operations: {
    keyword: {
      ontologyId:
        'https://graph.cloud.seek.com/Spec-Req/fbb7d944-d72c-4f4f-b89d-112f3e242900',
      text: 'General Operations',
    },
  },
  Cleaning: {
    keyword: {
      ontologyId:
        'https://graph.cloud.seek.com/Spec-Req/f569824e-546f-4f86-9579-1546a4b868f9',
      text: 'Cleaning',
    },
  },
  Providing_Support: {
    keyword: {
      ontologyId:
        'https://graph.cloud.seek.com/Spec-Req/31abf263-8308-4ce4-ba82-8a1eb3a0e5c3',
      text: 'Providing Support',
    },
  },
  Drilling_Operations: {
    keyword: {
      ontologyId:
        'https://graph.cloud.seek.com/Spec-Req/fad75177-e976-4711-b1e0-eb00e2cee6ab',
      text: 'Drilling Operations',
    },
  },
  Mobilisation: {
    keyword: {
      ontologyId:
        'https://graph.cloud.seek.com/Spec-Req/0f76605e-fe51-4a6b-b417-ba0178b73fab',
      text: 'Mobilisation',
    },
  },
  Australian_HR_Licence: {
    keyword: {
      ontologyId:
        'https://graph.cloud.seek.com/Spec-Req/995eceac-da26-4dc3-9f98-1ed07eea7f2b',
      text: 'Australian HR Licence',
    },
  },
  Criminal_History_Check: {
    keyword: {
      ontologyId:
        'https://graph.cloud.seek.com/Spec-Req/1cb148f6-6622-46d3-a2ee-43d98b23413b',
      text: 'Criminal History Check',
    },
  },
  DotNet: {
    keyword: {
      text: '.NET',
      ontologyId:
        'https://graph.cloud.seek.com/Spec-Req/97662222-086e-4d7e-b95e-3f2413611d9a',
      __typename: 'OntologyStructuredData',
    },
    __typename: 'Skill',
  },
  Web_Development: {
    keyword: {
      text: 'Web Development',
      ontologyId:
        'https://graph.cloud.seek.com/Spec-Req/83e4c1fa-37a2-4ad2-8c8e-bc33765ba7ec',
      __typename: 'OntologyStructuredData',
    },
    __typename: 'Skill',
  },
  CSharp: {
    keyword: {
      text: 'C#',
      ontologyId:
        'https://graph.cloud.seek.com/Spec-Req/f5476482-dcee-4f3d-8703-0fb5032db2b5',
      __typename: 'OntologyStructuredData',
    },
    __typename: 'Skill',
  },
  JavaScript: {
    keyword: {
      text: 'JavaScript',
      ontologyId:
        'https://graph.cloud.seek.com/Spec-Req/88dc05ae-4673-41dd-a83e-570c999523e7',
      __typename: 'OntologyStructuredData',
    },
    __typename: 'Skill',
  },
  AngularJS: {
    keyword: {
      text: 'AngularJS',
      ontologyId:
        'https://graph.cloud.seek.com/Spec-Req/a0e455c7-c6b0-48f7-b288-e04c84a0a5ee',
      __typename: 'OntologyStructuredData',
    },
    __typename: 'Skill',
  },
  React: {
    keyword: {
      text: 'React',
      ontologyId:
        'https://graph.cloud.seek.com/Spec-Req/e0fc73b7-4149-4a90-8df5-965da8438fbb',
      __typename: 'OntologyStructuredData',
    },
    __typename: 'Skill',
  },
  Front_end_SPAs: {
    keyword: {
      text: 'Front-end SPAs',
      ontologyId:
        'https://graph.cloud.seek.com/Spec-Req/2ade1c90-bb67-4bab-9386-e44a3128a9c8',
      __typename: 'OntologyStructuredData',
    },
    __typename: 'Skill',
  },
  TypeScript: {
    keyword: {
      text: 'Typescript Programming',
      ontologyId:
        'https://graph.cloud.seek.com/Spec-Req/c7304e46-a3e7-45f1-a038-11f5af499cae',
      __typename: 'OntologyStructuredData',
    },
    __typename: 'Skill',
  },
  GraphQL: {
    keyword: {
      text: 'Graphql',
      ontologyId:
        'https://graph.cloud.seek.com/Spec-Req/c048fc05-13ba-4ada-a6a2-3bd2690535b6',
      __typename: 'OntologyStructuredData',
    },
    __typename: 'Skill',
  },
};

const matched = [
    skills.DotNet,
    skills.Web_Development,
    skills.CSharp,
    skills.JavaScript,
    skills.AngularJS,
    skills.TypeScript,
  ],
  inferred = [skills.Front_end_SPAs, skills.GraphQL],
  unmatched = [skills.Australian_HR_Licence, skills.Criminal_History_Check];

const base = {
  matched,
  inferred,
  unmatched,
} as MatchedQualities;

const oneMatch = {
  ...base,
  matched: matched?.slice(0, 1),
} as MatchedQualities;

const twoMatches = {
  ...base,
  matched: matched?.slice(0, 2),
} as MatchedQualities;

const threeMatches = {
  ...base,
  matched: matched?.slice(0, 3),
} as MatchedQualities;

const fourMatches = {
  ...base,
  matched: matched?.slice(0, 4),
} as MatchedQualities;

const fiveMatches = {
  ...base,
  matched: matched?.slice(0, 5),
} as MatchedQualities;

const sixMatches = {
  ...base,
  matched: matched?.slice(0, 5),
} as MatchedQualities;

const noMatches = { ...base, inferred, unmatched } as MatchedQualities,
  noInferred = { ...base, matched, unmatched } as MatchedQualities,
  noUnmatched = { ...base, matched, inferred } as MatchedQualities,
  onlyMatched = { ...base, matched } as MatchedQualities,
  onlyInferred = { ...base, inferred } as MatchedQualities,
  onlyUnmatched = { ...base, unmatched } as MatchedQualities;

export const qualitiesData: Record<string, MatchedQualities> = {
  '29677777': oneMatch,
  '29752529': twoMatches,
  '29663971': threeMatches,
  '29663948': fourMatches,
  '29800267': fiveMatches,
  '29800268': sixMatches,
  '29800266': noMatches,
  '29800264': noInferred,
  '29800265': noUnmatched,
  '29800262': onlyMatched,
  '29800261': onlyInferred,
  '34536098': onlyUnmatched,
};
