import { getJobDetailsHead } from '@seek/seek-jobs-seo';
import { Fragment, useEffect, useMemo } from 'react';
// @ts-expect-error: non-ts file
import { provideHooks } from 'redial';
import loadable from 'sku/@loadable/component';
import { v4 as uuid } from 'uuid';

import ExpiredJob from 'src/components/ExpiredJob/ExpiredJob';
import PageLoader from 'src/components/PageLoader/PageLoader';
import PageNotFound from 'src/components/PageNotFound/PageNotFound';
import { SafetyHelmet } from 'src/components/SafetyHelmet/SafetyHelmet';
import { useAppConfig } from 'src/config/appConfig';
import { useSaveJobFromQueryParam } from 'src/hooks/useSaveJob/utils/useSaveJobFromQueryParam';
import { useViewJob } from 'src/hooks/useViewedJobs/useViewedJobs';
import { getUserAgent } from 'src/modules/chalice-user-agent/isomorphic-useragent';
import { logger } from 'src/modules/logger';
import { jobOrExpiredJobRegex } from 'src/modules/routes-regexp';
import {
  clearJobDetails,
  fetchUnifiedJob,
  fetchUnifiedJobPersonalised,
  jobDetailsPageLoaded,
} from 'src/store/jobdetails';
import type { JobDetails } from 'src/store/jobdetails/types';
import { useSelector } from 'src/store/react';
import {
  selectIsJobDetailsPending,
  selectJobDetailsResult,
  selectLocation,
  selectUserTestHeaders,
  selectXRealIp,
} from 'src/store/selectors';
import type { RedialLocals } from 'src/types/RedialLocals';
import {
  getJobActionOrigin,
  getViewJobOriginRef,
} from 'src/utils/eventCapture/eventCaptureUtils';

import {
  createGetSolMetadataForJDP,
  trackJobDetailsLoaded,
} from '../hooks/trackJobDetailsLoaded';
import { useSetHubbleTags } from '../modules/hubble';

export const JobDetailsPageView = loadable(
  /* #__LOADABLE__ */ () =>
    import(
      /* webpackChunkName: "JobDetailsPage" */ 'src/components/JobDetailsPage/JobDetailsPage'
    ),
  {
    resolveComponent: (m) => m.default,
  },
);

const extractIdFromPath = (path?: string) => {
  if (!path) {
    return '';
  }
  const match = path.match(jobOrExpiredJobRegex);
  return match ? match[2] : '';
};

let jobDetailsViewedCorrelationId: string;
const getJDVCorrelationId = (): void => {
  jobDetailsViewedCorrelationId = uuid();
};

const hooks = {
  first: () => {
    getJDVCorrelationId();
  },
  fetch: ({
    analyticsFacade,
    apolloClient,
    dispatch,
    getState,
    path,
    zone,
    cookies,
    languageCode,
    locale,
    country,
    xRealIp,
  }: RedialLocals) => {
    const jobId = extractIdFromPath(path);
    const existingJobId = getState().jobdetails.result?.job?.id;

    if (existingJobId && jobId !== String(existingJobId)) {
      dispatch(clearJobDetails());
    }

    return dispatch(
      fetchUnifiedJob({
        analyticsFacade,
        apolloClient,
        jobId,
        jobDetailsViewedCorrelationId,
        jobseekerSessionId: cookies.JobseekerSessionId,
        zone,
        locale,
        languageCode,
        countryCode: country,
        xRealIp,
      }),
    );
  },
  defer: ({
    apolloClient,
    authenticated,
    dispatch,
    path,
    cookies,
    languageCode,
    locale,
    zone,
    xRealIp,
  }: RedialLocals) => {
    const jobId = extractIdFromPath(path);

    if (authenticated) {
      return dispatch(
        fetchUnifiedJobPersonalised({
          apolloClient,
          jobId,
          jobseekerSessionId: cookies.JobseekerSessionId,
          jobDetailsViewedCorrelationId,
          languageCode,
          locale,
          zone,
          xRealIp,
        }),
      );
    }
    return;
  },
  pageLoad: ({
    analyticsFacade,
    dispatch,
    getState,
    query,
    apolloClient,
    path,
  }: RedialLocals) => {
    const state = getState();
    const id = extractIdFromPath(path);
    const currentPath = state.location.pathname;
    const prevPath = state.location.prevPathname;

    const jobDetails = state.jobdetails.result;

    const jobListingType = jobDetails?.job?.tracking?.adProductType as
      | string
      | undefined;

    const getSolMetadata = createGetSolMetadataForJDP({
      experiments: state.experiments,
      hash: state.location.hash,
      jobId: id,
      query,
    });

    /**
     * When the current pathname === previous pathname it a signal that the user has
     * accessed this job via an external source.
     */
    const jobListingPosition =
      currentPath === prevPath
        ? -1
        : query?.pos ?? getSolMetadata().sectionRank;

    const ref = getViewJobOriginRef();
    const origin = getJobActionOrigin();

    trackJobDetailsLoaded(
      analyticsFacade,
      {
        correlationId: jobDetailsViewedCorrelationId,
        getSolMetadata,
        jobDetails,
        jobListingPosition,
        jobListingType,
        jobViewType: 'standalone',
        omnitureTracking: query?.tracking,
        jobViewOrigin: ref,
        jobActionOrigin: origin,
      },
      state,
      apolloClient,
    );

    dispatch(jobDetailsPageLoaded());
  },
};

const ExpiredJobWithSafetyHelmet = (
  helmetProps: ReturnType<typeof getJobDetailsHead>,
) => (
  <Fragment>
    <SafetyHelmet {...helmetProps} />
    <ExpiredJob />
  </Fragment>
);

const JobDetails = () => {
  const location = useSelector(selectLocation);
  const jobDetails = useSelector(selectJobDetailsResult);
  const jobPending = useSelector(selectIsJobDetailsPending);
  const testHeaders = useSelector(selectUserTestHeaders);
  const ipAddress = useSelector(selectXRealIp);
  const { brand } = useAppConfig();
  const userAgent = getUserAgent();
  useSetHubbleTags(testHeaders);

  useSaveJobFromQueryParam();

  const viewJob = useViewJob(jobDetails?.job.id);
  useEffect(() => {
    viewJob();
  }, [viewJob]);

  const jobIsExpired = useMemo(() => {
    if (jobDetails?.job && jobDetails?.job?.status) {
      const isExpired =
        jobDetails?.job?.status !== 'Active' &&
        location.pathname.search('expiredjob') < 0;

      if (isExpired) {
        logger.info(
          {
            jobId: jobDetails?.job.id,
            status: jobDetails?.job.status,
            ipAddress,
            userAgent,
          },
          'Job is expired',
        );
      }

      return isExpired;
    }
    return false;
  }, [jobDetails?.job, location.pathname, userAgent, ipAddress]);

  const helmetProps = useMemo(() => {
    if (jobDetails) {
      return getJobDetailsHead({
        abstract: jobDetails?.job?.abstract,
        location: jobDetails?.job?.location?.label,
        title: jobDetails?.job?.title,
        brand,
      });
    }
    return {};
  }, [jobDetails, brand]);

  if (!jobDetails) {
    return jobPending ? <PageLoader /> : <PageNotFound />;
  }

  if (jobIsExpired) {
    return <ExpiredJobWithSafetyHelmet {...helmetProps} />;
  }

  return (
    <Fragment>
      <SafetyHelmet {...helmetProps} />
      {jobDetails && <JobDetailsPageView jobDetails={jobDetails} />}
    </Fragment>
  );
};

export default provideHooks(hooks)(JobDetails);
