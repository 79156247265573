import {
  Box,
  Stack,
  PageBlock,
  Columns,
  Column,
  Button,
  IconFilter,
  useResponsiveValue,
} from 'braid-design-system';
import { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { renderRoutes } from 'react-router-config';
// @ts-expect-error: non-ts file
import { provideHooks } from 'redial';

import type { TSeekExperiments } from 'src/store/experiments/types';
import { useDispatch, useSelector } from 'src/store/react';
import {
  expandSearchFilters,
  getCounts,
  updateCriteria,
} from 'src/store/search';
import {
  selectFeatureFlag,
  selectIsFiltersExpanded,
  selectIsHomePage,
} from 'src/store/selectors';
import type { RedialLocals } from 'src/types/RedialLocals';

import { DynamicPill } from './DynamicPill/DynamicPill.tsx';
import RefineBar from './RefineBar/RefineBar';
import SearchBar from './SearchBar/SearchBar';
import { SecondaryFilter } from './SecondaryFilter/SecondaryFilter';
import { useSecondaryFilterContext } from './SecondaryFilter/context/SecondaryFilterContext';

import * as styles from './Search.css';

const hooks = {
  first({ dispatch, path = '', query, zone }: RedialLocals) {
    return dispatch(
      updateCriteria({
        path,
        query,
        zone,
      }),
    );
  },
  defer({
    dispatch,
    country,
    path = '',
    query,
    cookies,
    zone,
    visitorId,
  }: RedialLocals) {
    return dispatch(
      getCounts({
        zone,
        country,
        path,
        query,
        cookies,
        solId: visitorId,
      }),
    );
  },
};

export interface SearchProps {
  route: Record<string, any>;
  experiments?: TSeekExperiments;
}

const Search = ({ route }: SearchProps) => {
  const filtersExpanded = useSelector(selectIsFiltersExpanded);
  const dispatch = useDispatch();
  const showSecondaryFilters = useSelector(
    selectFeatureFlag('secondaryFilters'),
  );
  const showDynamicPills = useSelector(selectFeatureFlag('dynamicPills'));

  const isHomePage = useSelector(selectIsHomePage);
  const isMobile =
    useResponsiveValue()({
      mobile: true,
      tablet: false,
    }) || false;

  const { setIsOpen } = useSecondaryFilterContext();

  const [isSearchInView, setIsSearchInView] = useState(true);

  const { ref: searchInViewRef } = useInView({
    onChange: (inView) => {
      setIsSearchInView(() => inView);
    },
    initialInView: true,
  });

  return (
    <Box height="full">
      <Box
        position="relative"
        // This is to ensure this will not cover Header's language switcher
        zIndex={2}
        background="brand"
        paddingBottom={{
          mobile: showSecondaryFilters ? 'small' : 'medium',
          tablet: 'small',
        }}
        paddingX={{
          mobile: 'none',
          tablet: showSecondaryFilters ? 'large' : 'medium',
        }}
        className={{
          [styles.branding]: !showSecondaryFilters,
          [styles.searchBarPadding]: !showSecondaryFilters,
          [styles.secondaryFilterPadding]: showSecondaryFilters,
        }}
      >
        {/* Mobile web*/}
        {showSecondaryFilters && isMobile && (
          <>
            <PageBlock width="large">
              <Columns space={isHomePage ? 'none' : 'xsmall'} reverse>
                <Column width="content">
                  <Box display={!isHomePage ? 'block' : 'none'}>
                    <Box
                      boxShadow="borderField"
                      borderRadius="standard"
                      padding="xsmall"
                      onClick={() => setIsOpen(true)}
                    >
                      <IconFilter />
                    </Box>
                  </Box>
                </Column>
                <Column>
                  <SearchBar collapsed={!isHomePage && filtersExpanded} />
                </Column>
              </Columns>
            </PageBlock>
            {showDynamicPills && (
              <Box paddingTop="small" display={!isHomePage ? 'block' : 'none'}>
                <DynamicPill />
              </Box>
            )}
          </>
        )}

        {/* desktop and tablet*/}
        {showSecondaryFilters && !isMobile && (
          <Box
            position="relative"
            zIndex={2}
            background="brand"
            paddingBottom="xsmall"
          >
            <PageBlock width="large">
              <Stack space="medium">
                <SearchBar collapsed={filtersExpanded} />
                <Box display="block">
                  <Columns space="small" reverse alignY="center">
                    <Column width="content">
                      <Button
                        variant="ghost"
                        tone="neutral"
                        onClick={() => {
                          setIsOpen(true);
                          dispatch(expandSearchFilters(true));
                        }}
                        data={{ automation: 'secondaryFilterButton' }}
                        icon={<IconFilter alignY="lowercase" />}
                      >
                        {'Filters'}
                      </Button>
                    </Column>
                    <Column>
                      {showDynamicPills && !isHomePage && <DynamicPill />}
                    </Column>
                  </Columns>
                </Box>
              </Stack>
            </PageBlock>
          </Box>
        )}

        {/* SearchBar and RefineBar without Secondary Filters and Dynamic Pills*/}
        {!showSecondaryFilters && (
          <PageBlock width="medium">
            <Box ref={searchInViewRef} data={{ automation: 'searchInView' }}>
              <Stack
                space={{
                  mobile: filtersExpanded ? 'xsmall' : 'none',
                  tablet: 'xsmall',
                }}
              >
                <SearchBar
                  collapsed={filtersExpanded}
                  isSearchInView={isSearchInView}
                />
                <RefineBar
                  expanded={filtersExpanded}
                  isSearchInView={isSearchInView}
                />
              </Stack>
            </Box>
          </PageBlock>
        )}

        {showSecondaryFilters && <SecondaryFilter />}
      </Box>
      {renderRoutes(route.routes)}
    </Box>
  );
};
export default provideHooks(hooks)(Search);
