import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { useAppConfig } from 'src/config/appConfig';
import type {
  JobDetailsPersonalisedQueryVariables,
  SearchSavedAndAppliedJobsQueryVariables,
} from 'src/graphql/graphql';
import { useTimezone } from 'src/hooks/useTimezone';
import { GET_JOB_DETAILS_PERSONALISED } from 'src/modules/graphql/queries';
import { SEARCH_SAVED_AND_APPLIED_JOBS } from 'src/modules/graphql/queries/savedJobs';
import type {
  GetJobDetailsPersonalised,
  SearchSavedAndAppliedJobs,
} from 'src/modules/graphql/queries/types';
import { useSelector } from 'src/store/react';
import {
  selectJobDetailsResult,
  selectResultsJobIds,
} from 'src/store/selectors';

export const useSavedJobsDataSERP = ({
  shouldSkip = false,
}: {
  shouldSkip?: boolean;
} = {}) => {
  // Search data for saved and applied jobs
  const jobIds = useSelector(selectResultsJobIds);
  const searchSavedAndAppliedJob = useQuery<
    SearchSavedAndAppliedJobs,
    SearchSavedAndAppliedJobsQueryVariables
  >(SEARCH_SAVED_AND_APPLIED_JOBS, {
    skip: !Boolean(jobIds?.length) || shouldSkip,
    variables: { jobIds },
  });

  return searchSavedAndAppliedJob;
};

export const useSavedJobsData = ({
  isJobDetailsView,
  shouldSkip,
}: {
  isJobDetailsView: boolean;
  shouldSkip?: boolean;
}) => {
  // Search data for saved and applied jobs
  const searchSavedAndAppliedJob = useSavedJobsDataSERP({ shouldSkip });

  // Job details personalised data to indicate if job is saved
  const { zone, locale, language: languageCode } = useAppConfig();
  const currentJobDetails = useSelector(selectJobDetailsResult);
  const timezone = useTimezone();

  const jobDetailsPersonalised = useQuery<
    GetJobDetailsPersonalised,
    JobDetailsPersonalisedQueryVariables
  >(GET_JOB_DETAILS_PERSONALISED, {
    skip: !Boolean(currentJobDetails) || shouldSkip,
    variables: {
      id: currentJobDetails?.job.id || '',
      languageCode,
      locale,
      timezone,
      zone,
    },
  });

  return useMemo(
    () =>
      isJobDetailsView && currentJobDetails
        ? jobDetailsPersonalised
        : searchSavedAndAppliedJob,
    [
      isJobDetailsView,
      currentJobDetails,
      searchSavedAndAppliedJob,
      jobDetailsPersonalised,
    ],
  );
};
