import querystring from 'query-string';

const mockSkillData = [
  {
    __typename: 'Skill',
    keyword: {
      __typename: 'OntologyStructuredData',
      text: 'Typescript Programming',
    },
  },
  {
    __typename: 'Skill',
    keyword: { __typename: 'OntologyStructuredData', text: 'Javascript' },
  },
  {
    __typename: 'Skill',
    keyword: { __typename: 'OntologyStructuredData', text: 'React' },
  },
  {
    __typename: 'Skill',
    keyword: { __typename: 'OntologyStructuredData', text: 'CSS' },
  },
  {
    __typename: 'Skill',
    keyword: { __typename: 'OntologyStructuredData', text: 'HTML' },
  },
  {
    keyword: {
      text: 'Graphql',
      ontologyId:
        'https://graph.cloud.seek.com/Spec-Req/c048fc05-13ba-4ada-a6a2-3bd2690535b6',
      __typename: 'OntologyStructuredData',
    },
    __typename: 'Skill',
  },
];
const resumeData = [{ id: '90b515d1-7916-4ca2-8d76-cc0d93d91702' }];

const defaultCandidateResolver = {
  __typename: 'Candidate',
  personalDetails: {
    firstName: 'Joe',
  },
  skills2: mockSkillData,
  resumes: resumeData,
  emailAddress: 'joe@gmail.com',
  id: 39675318,
  trackingId: '2cef3f488f07f1e946cd6a6bdcc62bf9',
  userCookie: {
    key: 'last-known-sol-user-id',
    value: '3364edb5-9fa8-40bc-9068-2cb3c032529c',
  },
};

const defaultMockResolvers = {
  Candidate: () => defaultCandidateResolver,
};

export default () => {
  const { testData = '' } =
    typeof window !== 'undefined'
      ? querystring.parseUrl(window.location.href).query
      : {};

  if (testData === 'apacSavedSearchesError') {
    return {
      ...defaultMockResolvers,
      Candidate: () => ({
        ...defaultCandidateResolver,
        apacSavedSearches: new Error('ERROR_WITH_APAC_SAVED_SEARCHES_RESOLVER'),
      }),
    };
  }

  if (testData === 'newCandidateNoProfileSetup') {
    return {
      ...defaultMockResolvers,
      Candidate: () => ({
        ...defaultCandidateResolver,
        apacSavedSearches: [],
      }),
    };
  }

  return defaultMockResolvers;
};
