import gql from 'graphql-tag';

export const GET_JOB_DETAILS = gql`
  query jobDetails(
    $jobId: ID!
    $jobDetailsViewedCorrelationId: String!
    $sessionId: String!
    $zone: Zone!
    $locale: Locale!
    $languageCode: LanguageCodeIso!
    $countryCode: CountryCodeIso2!
    $timezone: Timezone!
  ) {
    jobDetails(
      id: $jobId
      tracking: {
        channel: "WEB"
        jobDetailsViewedCorrelationId: $jobDetailsViewedCorrelationId
        sessionId: $sessionId
      }
    ) {
      job {
        sourceZone
        tracking {
          adProductType
          classificationInfo {
            classificationId
            classification
            subClassificationId
            subClassification
          }
          hasRoleRequirements
          isPrivateAdvertiser
          locationInfo {
            area
            location
            locationIds
          }
          workTypeIds
          postedTime
        }
        id
        title
        phoneNumber
        isExpired
        expiresAt {
          dateTimeUtc
        }
        isLinkOut
        contactMatches {
          type
          value
        }
        isVerified
        abstract
        content(platform: WEB)
        status
        listedAt {
          label(
            context: JOB_POSTED
            length: SHORT
            timezone: $timezone
            locale: $locale
          )
          dateTimeUtc
        }
        salary {
          currencyLabel(zone: $zone)
          label
        }
        shareLink(platform: WEB, zone: $zone, locale: $locale)
        workTypes {
          label(locale: $locale)
        }
        advertiser {
          id
          name(locale: $locale)
          isVerified
          registrationDate {
            dateTimeUtc
          }
        }
        location {
          label(locale: $locale, type: LONG)
        }
        classifications {
          label(languageCode: $languageCode)
        }
        products {
          branding {
            id
            cover {
              url
            }
            thumbnailCover: cover(isThumbnail: true) {
              url
            }
            logo {
              url
            }
          }
          bullets
          questionnaire {
            questions
          }
          video {
            url
            position
          }
        }
      }
      companyProfile(zone: $zone) {
        id
        name
        companyNameSlug
        shouldDisplayReviews
        branding {
          logo
        }
        overview {
          description {
            paragraphs
          }
          industry
          size {
            description
          }
          website {
            url
          }
        }
        reviewsSummary {
          overallRating {
            numberOfReviews {
              value
            }
            value
          }
        }
        perksAndBenefits {
          title
        }
      }
      companySearchUrl(zone: $zone, languageCode: $languageCode)
      learningInsights(platform: WEB, zone: $zone, locale: $locale) {
        analytics
        content
      }
      companyTags {
        key(languageCode: $languageCode)
        value
      }
      restrictedApplication(countryCode: $countryCode) {
        label(locale: $locale)
      }
      sourcr {
        image
        imageMobile
        link
      }
      gfjInfo {
        location {
          countryCode
          country(locale: $locale)
          suburb(locale: $locale)
          region(locale: $locale)
          state(locale: $locale)
          postcode
        }
        workTypes {
          label
        }
      }
    }
  }
`;

export const GET_JOB_DETAILS_PERSONALISED = gql`
  query jobDetailsPersonalised(
    $id: ID!
    $tracking: JobDetailsTrackingInput
    $locale: Locale!
    $zone: Zone!
    $languageCode: LanguageCodeIso!
    $timezone: Timezone!
  ) {
    jobDetails(id: $id, tracking: $tracking) {
      personalised {
        isSaved
        appliedDateTime {
          shortAbsoluteLabel(locale: $locale, timezone: $timezone)
        }
        topApplicantBadge {
          label(locale: $locale)
          description(locale: $locale, zone: $zone)
        }
        salaryMatch {
          ... on JobProfileMissingSalaryPreference {
            label(locale: $locale)
          }
          ... on JobProfileSalaryMatch {
            label(locale: $locale)
            salaryPreference(locale: $locale, languageCode: $languageCode) {
              id
              description
              country {
                countryCode
                name
              }
              currencyCode
              amount
              salaryType
            }
          }
          ... on JobProfileSalaryNoMatch {
            label(locale: $locale)
          }
        }
      }
    }
  }
`;

export const GET_JOB_DETAILS_MATCHED_QUALITIES = gql`
  query GetMatchedQualities(
    $jobDetailsId: ID!
    $tracking: JobDetailsTrackingInput
  ) {
    jobDetails(id: $jobDetailsId, tracking: $tracking) {
      personalised {
        qualities {
          matched {
            ...skill
          }
          inferred {
            ...skill
          }
          unmatched {
            ...skill
          }
        }
      }
    }
  }

  fragment skill on Skill {
    keyword {
      ontologyId
      text
    }
  }
`;
