import type { SearchResultJob, SearchResultJobV5 } from '@seek/chalice-types';
import { JOB_DISPLAY_TYPE_QUERY_KEY } from '@seek/job-details-view';
import { enrichLocation } from '@seek/seek-jobs-seo';

import { SELECTED_JOB_ID_SEARCH_QUERY_KEY } from 'src/store/results';
import type { selectLocation } from 'src/store/selectors';

export const persistJobUrl = ({
  currentLocation,
  job: { id, displayType },
}: {
  currentLocation: ReturnType<typeof selectLocation>;
  job: Pick<SearchResultJob | SearchResultJobV5, 'id' | 'displayType'>;
}) => {
  const targetUrl =
    enrichLocation({
      ...currentLocation,
      query: {
        ...currentLocation.query,
        [SELECTED_JOB_ID_SEARCH_QUERY_KEY]: id,
        [JOB_DISPLAY_TYPE_QUERY_KEY]: displayType,
      },
    }).href ?? '';

  if (targetUrl && currentLocation?.url) {
    history.replaceState(
      {},
      '',
      targetUrl.replace(/(^\w+:)/, new URL(currentLocation.url).protocol),
    );
  } else {
    history.replaceState({}, '', targetUrl);
  }
};

export const removePersistedJobUrl = (
  currentLocation: ReturnType<typeof selectLocation>,
) => {
  if (currentLocation?.url) {
    const url = new URL(currentLocation.url);
    url.searchParams.delete(SELECTED_JOB_ID_SEARCH_QUERY_KEY);
    url.searchParams.delete(JOB_DISPLAY_TYPE_QUERY_KEY);
    history.replaceState({}, '', url.href);
  }
};

// TODO While we support both V4 and V5 search responses, the selected jobId can either be a string (V5) or number (V4)
// This should be a temporary workaround until we fully cutover and we can consolidate types
export const compareJobIds = (
  currentJobId?: number | string,
  selectedJobId?: number | string,
) => {
  if (!currentJobId || !selectedJobId) {
    return false;
  }

  const convertedCurrentJobId =
    typeof currentJobId === 'string'
      ? parseInt(currentJobId, 10)
      : currentJobId;
  const convertedSelectedJobId =
    typeof selectedJobId === 'string'
      ? parseInt(selectedJobId, 10)
      : selectedJobId;

  return convertedCurrentJobId === convertedSelectedJobId;
};
