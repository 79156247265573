import type {
  GetCandidateDetailsQuery,
  PersonalDetails,
} from 'src/graphql/graphql';
import type { EventCaptureTestTags } from 'src/utils/eventCapture/eventCaptureUtils';
import type { TestHeaders } from 'src/utils/productionTesting/productionTesting';

import type { Meta } from '../meta';

export const UPDATE_AUTHENTICATED = 'UPDATE_AUTHENTICATED';
export const GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS';
export const UPDATE_SESSION = 'UPDATE_SESSION';
export const UPDATE_TEST_HEADERS = 'UPDATE_TEST_HEADERS';
export const UPDATE_TEST_TAGS = 'UPDATE_TEST_TAGS';
export const GET_ACCOUNT_ERROR = 'GET_ACCOUNT_ERROR';

export interface UserAccount {
  emailAddress: string;
  personalDetails: Pick<PersonalDetails, 'firstName'> | undefined;
  trackingId: string | undefined;
  id: number | undefined;
  identity:
    | Pick<NonNullable<GetCandidateDetailsQuery['viewer']>['identity'], 'actor'>
    | undefined;
  hasResume: boolean;
  skillCount: number;
}

export interface UserState {
  authenticated?: boolean;
  firstName: string;
  emailAddress: string;
  userClientId: string;
  seekerId?: number;
  sessionId?: string;
  trackingId?: string;
  actorId?: string;
  testHeaders: TestHeaders;
  testTags?: EventCaptureTestTags;
  hasResume: boolean;
  skillCount: number;
}

interface GetAccountSuccessAction {
  type: typeof GET_ACCOUNT_SUCCESS;
  payload: UserAccount;
  meta: Meta;
}

interface UpdateAuthenticatedAction {
  type: typeof UPDATE_AUTHENTICATED;
  payload: {
    authenticated: boolean;
  };
  meta?: Meta;
}

interface UpdateTestHeadersAction {
  type: typeof UPDATE_TEST_HEADERS;
  payload: {
    testHeaders: TestHeaders;
  };
}

interface UpdateTestTagsAction {
  type: typeof UPDATE_TEST_TAGS;
  payload: {
    testTags?: EventCaptureTestTags;
  };
}

export type UserAction =
  | UpdateAuthenticatedAction
  | {
      type: typeof UPDATE_SESSION;
      payload: { userClientId: string; sessionId: string };
    }
  | GetAccountSuccessAction
  | {
      type: typeof GET_ACCOUNT_ERROR;
      error: true;
      payload: Error;
    }
  | UpdateTestHeadersAction
  | UpdateTestTagsAction;
