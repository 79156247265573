import { isProduction } from '..';
import type { AppConfig } from '../types';

export default {
  zone: 'anz-2',
  defaultLocale: 'en-NZ',
  availableLocales: ['en-NZ'],
  timedBanners: {},
  zoneFeatures: {
    NUDGE_ENABLED: true,
    HOMEPAGE_BANNER_TYPE: 'SEEKRET_ANZ_BANNER',
    SEARCH_PAGE_SIZE: 20,
    SHOW_FLOATING_SAVE_SEARCH: true,
    SHOW_JOBCARD_TEASER_IN_SPLITVIEW: true,
    AUTO_SELECT_SPLIT_VIEW_FIRST_JOB: false,
    SHOW_MARKETING_AD_SPLIT_VIEW: true,
    LMIS_ENABLED: true,
    BEHAVIOURAL_CUES_ENABLED: false,
    BEHAVIOURAL_CUES_FILTERS_ENABLED: false,
    LOGGED_OUT_RECS: false,
    REMOTE_SEARCH_FILTER: false,
    REMOTE_SEARCH_FILTER_NEW_LABEL: true,
    DYNAMIC_PILLS: false,
    SECONDARY_FILTERS: false,
    ENABLE_V5_JOB_SEARCH: false,
    KEYWORD_AUTOSUGGEST_V2: true,
    NEW_JOB_CARD_DENSITY: false,
    ENABLE_VERIFIED_HIRER_BADGE: true,
    SERP_JOBCARD_INFO_DENSITY_1: false,
    MATCHED_QUALITIES: false,
  },
  BRANCH_IO_KEY: isProduction
    ? 'key_live_hpVf26efkYxTQTj88NJzZpchsqglHGRe'
    : 'key_test_khOf96nlb9tQIPl47VZiEcbpszklPUNw',
} as AppConfig;
